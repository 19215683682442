export default [


  {
    meta: 'Workshop',
    title: 'JavaScript OOP ',
    path:
      'https://github.com/irenapopova/WORKSHOPS-DCI',
  },

]
