import React from 'react'
import { Link } from 'gatsby'

import { slugify } from '../utils/helpers'
/* import { react } from '../../../../Library/Caches/typescript/4.2/node_modules/@babel/types/lib/index'
*/
const PostItem = ({ node, tags }) => {
  return (

    <Link to={node.slug} className="row" key={node.id}>
      <div className="cell">
        <time>{node.date}</time>
        <div>{node.title}</div>
      </div>
      {tags && (
        <div className="cell tags">
          {node.tags &&
            node.tags.map((tag) => (
              <Link
                key={tag}
                to={`/tags/${slugify(tag)}`}
                className={`tag-${tag}`}
              >
                {tag}
              </Link>
            ))}
        </div>
      )}
    </Link>

  )

}

export default function Posts({ data, tags }) {
  const groupedValues = data.reduce((result, current, index) => {
    const date = new Date(current.date)
    const currentYear = date.getFullYear()

    if (!result[currentYear]) { result[currentYear] = [] }
    result[currentYear].push(current)
    return result
  }, {})
  return (
    <>
      {
        Object.keys(groupedValues).sort((a, b) => b - a).map(key => {
          return (
            <>
              <h2>{key}</h2>
              <div className={tags ? 'grid posts with-tags' : 'grid posts'}>
                {groupedValues[key].map((node) => <PostItem node={node} tags={tags} />)}
              </div>
            </>

          )
        }

        )
      }
    </>
  )
}
