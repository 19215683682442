import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import "@fortawesome/fontawesome-free/css/all.css";

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import Lists from '../components/Lists'
import Projects from '../components/Projects'
import SEO from '../components/SEO'
import SearchForm from '../components/SearchForm'

import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

import projects from '../data/projects'
import workshops from '../data/workshops'
import speaking from '../data/speaking'

import irena from '../../content/images/irena.png'

export default function BlogIndex({ data, ...props }) {
  const latest = data.latest.edges
  const popular = data.popular.edges
  const simplifiedLatest = useMemo(() => getSimplifiedPosts(latest), [latest])
  const simplifiedPopular = useMemo(() => getSimplifiedPosts(popular), [
    popular,
  ])

  const Section = ({ title, children, ...props }) => (
    <section {...props}>
      <h2>{title}</h2> {" "}
      {/* <h2 style={{ backgroundColor: "#fadb6b" }} >{title}</h2> */}
      {children}
    </section>
  )

  return (
    <Layout>
      <Helmet title={config.siteTitle} />
      <SEO />
      <section className="lead">
        <div className="container">
          <div className="copy">
            <h1>
              👋 Hey! I am{' '}
              <Link to="/about" className="name">
                Irena Popova
              </Link>
              .<br /> I am a Front-End Developer & a Linguist.  👩🏻‍💻
            </h1>
            <p>
              Welcome to my digital notebook of all I have learned and discovered over the years. It is a collection of my work - notes, articles, tutorials, my workshop topics and exercises. All that is related to web technologies & coding. You can discover more in my{' '}
              <Link to="/blog"> 📝 - blog section</Link>, view the{' '}
              <Link to="/guides">links to tutorials &amp; workshops</Link>, discover more challenges or find out {' '}
              <Link to="/about">about me</Link>.
              </p>
          </div>

          <div className="image">
            <img src={irena} alt="Irena" />
          </div>
        </div>
      </section>
      <div className="container">
        <Section title="Latest posts">
          <Posts data={simplifiedLatest} tags />
        </Section>
        <Section title="Popular">
          <Posts data={simplifiedPopular} tags />
        </Section>
        <Section title="Projects">
          <Projects data={projects} />
        </Section>
        <Section title="Workshops &amp; Presentations" className="medium">
          <Lists data={workshops} />
        </Section>
        <Section title="Speaking" className="medium">
          <Lists data={speaking} />
        </Section>
        <Section title="Newsletter" className="small">
          <p>
            I sent out an email when I have created something new. No spam,
            easy unsubscribe whenever. Keep in touch!
          </p>
          <a
            href="https://irena.substack.com/subscribe"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Subscribe to the Email list
          </a>
        </Section>
        <Section title="Search" className="small">
          <p>Search anything in the blog.</p>
          <SearchForm {...props} />
        </Section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
