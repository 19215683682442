export default [
  {
    icon: '📝',
    title: 'Quiz',
    path: 'https://irenapopova.github.io/Quiz/',
    source: 'https://github.com/irenapopova/Quiz',
    description: 'A Quiz app.',
  },
  {
    icon: '📻',
    title: 'Online Radio',
    path: 'https://irenapopova.github.io/online-radio/',
    source: 'https://github.com/irenapopova/online-radio',
    description:
      'An online radio.',
  },
  {
    icon: '🏖',
    title: 'Weather App',
    path: 'https://irenapopova.github.io/weatherapp/',
    source: 'https://github.com/irenapopova/weatherapp',
    description:
      'The application shows the weather in the region. ',
  },
  {
    icon: ' 🎮',
    title: 'Memory Match Game',
    source: 'https://irenapopova.github.io/Project-Memory-Game/',
    description: 'A simple memory match game.',
  },
  {
    icon: ' ❓',
    title: 'Q&A App',
    path: 'http://ask-me.surge.sh/',
    source: 'https:/',
    description:
      'A Q&A application for students.',
  },

  {
    icon: ' 🎹',
    title: 'Spotify-API',
    source: 'https://irenapopova.github.io/Spotify-API/',
    description: 'Spotify-api project',
  },
]
